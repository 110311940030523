import React from "react"
import Components from "src/storyblok/Components"
import Footer from "src/storyblok/GlobalModules/Footer"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story && state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = props.pageContext.story
    const footer = props.pageContext.footer
    const menu = props.pageContext.menu

    return { story, footer, menu }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let story = this.state.story ? this.state.story : null
    let content = story ? story.content : null
    let footer = this.state.footer.content ? this.state.footer.content : null
    // let menu = this.state.menu.content

    return (
      <React.Fragment>
        {React.createElement(Components(content.component), {
          storyID: story.uuid,
          tag_list: story.tag_list,
          name: story.name,
          slug: story.slug,
          isStartPage: story.slug === "home",
          isRootPage: story.is_startpage,
          full_slug: story.full_slug,
          date: story.created_at,
          blok: story.content,
          footer: footer,
        })}
        <Footer blok={footer} />
      </React.Fragment>
    )
  }
}

export default StoryblokEntry
